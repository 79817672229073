@import "./assets/styles/text.scss";
@import "/node_modules/flag-icons/css/flag-icons.min.css";

.chat-page {
  height: 100vh;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  gap: 0.25rem;
}

.expand-anim {
  animation: expand 0.1s ease-in forwards;
}

@keyframes expand {
  from {
    width: 0;
  }
  to {
    width: 400px;
    max-width: calc(100vw);
  }
}

.colapse-anim {
  animation: colapse 0.1s ease-in forwards;
}

@keyframes colapse {
  from {
    width: 400px;
    max-width: calc(100vw);
  }
  to {
    width: 0;
  }
}
@media only screen and (max-width: 600px) {
  @keyframes expand {
    from {
      width: 0;
    }
    to {
      width: unset;
    }
  }
}

.border-radius {
  border-radius: 8px !important;
}

.dafault-box-shadow {
  box-shadow: 0 2px 7px 1px rgba(23, 26, 31, 0.1) !important;
}

.MuiInputBase-root {
  @extend .border-radius;
}

.MuiAccordion-root {
  @extend .border-radius;
  @extend .dafault-box-shadow;
}

.MuiTableContainer-root {
  @extend .border-radius;
  @extend .dafault-box-shadow;
}

.MuiCard-root {
  @extend .border-radius;
  @extend .dafault-box-shadow;
}

.MuiButton-outlined {
  @extend .border-radius;
}

.MuiButton-contained {
  border-radius: 1rem !important;
}

:focus {
  outline: none !important;
}

.v-auto-fr {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
}

.v-fr-auto {
  display: grid;
  grid-template-rows: 1fr auto;
}

.gap-2 {
  gap: 0.5rem;
}

.gap {
  gap: 1rem;
}

.fr-auto-layput {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
}

.card-like-border {
  border: 1px solid black;
}

.no-box-shadow {
  box-shadow: none !important;
}

pre {
  white-space: pre-wrap;
}

.center-items {
  align-items: center;
  justify-content: center;
  display: flex;
}

.bl {
  border-left: 1px solid;
}

.bg-img-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
