.training-back-layout {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .back-title {
    display: none;
  }
}
