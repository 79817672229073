.explanation-section-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

@media (max-width: 600px) {
  .explanation-section-layout {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
