.home-intro-container {
  background-image: url("../../../../../public/images/hero_image.png");
  height: 485px;
}
.logo-font {
  line-height: normal;
  font-size: 10vw;
}
.welcome-font {
  line-height: normal;
  font-size: 6vw;
}

.slogan-font {
  font-size: 1.25rem;
  padding: 4px 12px;
}

@media (max-width: 600px) {
  .slogan-font {
    font-size: 1rem;
    padding: 0 8px;
  }
}

@media (min-width: 1024px) {
  .logo-font {
    font-size: 7rem;
  }
  .welcome-font {
    font-size: 4rem;
  }
}
