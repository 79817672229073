.training-section-layout {
  display: grid;
  grid-template-columns: repeat(
    3,
    minmax(0, 1fr)
  ); /* Ensure columns can shrink */
  gap: 2rem;
}

.training-section-layout .item {
  max-width: 350px; /* Fixed width for each item */
  margin-bottom: 20px; /* Adjust margin as needed */
}

@media (max-width: 1024px) {
  .training-section-layout {
    grid-template-columns: 1fr;
  }

  .training-section-layout .item {
    max-width: 500px; /* Fixed width for each item */
  }
}
