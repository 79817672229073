@import "./variables.scss";

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reactMarkDown {
  pre {
    background: rgb(245, 245, 245);
    color: rgb(100, 100, 100);
    padding: 1rem 0.5rem;
    margin: 1rem 0;
  }
  ul {
    padding: 0.5rem 0;
  }
  li {
    margin-left: 2rem;
    list-style-type: circle;
  }
}

.muted-text {
  color: $muted-color;
  // color: red;
}
