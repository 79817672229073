.chat-panel-layout {
  display: grid;
  grid-template-rows: 1fr auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.hello-chat {
  margin-top: calc(50vh - 170px);
}

.user-message {
  text-align: start;
  background-color: #ebff59;
  color: black;
}

.system-message {
  background-color: black;
  text-align: start;
  color: white;
}

@media (min-width: 1024px) {
  .chat-panel-layout {
    background-size: unset;
  }
}
