.tips-item-layout {
  display: grid;
  grid-template-columns: minmax(100px, 200px) 1fr auto;
  gap: 1rem;
  height: 175px;
}

.tips-item-img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center center;
}

.tips-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.h-open-tip {
  display: none;
}

@media (max-width: 600px) {
  .v-open-tip {
    display: none;
  }
  .h-open-tip {
    display: block;
  }
  .h-tips-title-layout {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
  }

  .tips-item-layout {
    grid-template-columns: unset;
    grid-template-rows: 200px 1fr;
    gap: 1rem;
    height: unset;
  }
  .link-description {
    display: unset;
    -webkit-line-clamp: unset;
  }
}
