.training-intro-title-layout {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
}

.training-intro-content-layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}

.training-intro-content-layout ul {
  list-style-type: disc;
  padding-left: 1rem;
}

.start-training-button {
  display: flex;
  align-items: self-end;
}

@media (max-width: 600px) {
  .training-intro-title-layout {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1024px) {
  .training-intro-content-layout {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}
