@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "BandeinsStrange";
  src: local("BandeinsStrange"),
    url(./assets/font/BandeinsStrange-Bold.woff) format("woff");
}

@font-face {
  font-family: "BandeinsStrangeExtended";
  src: local("BandeinsStrange"),
    url(./assets/font/BandeinsStrange-BoldExtendedHalf.woff) format("woff");
}

@font-face {
  font-family: "Sen";
  src: local("Sen"),
    url(./assets/font/Sen-VariableFont_wght.woff) format("woff");
}

* {
  font-family: "Sen", sans-serif !important;
}

strong {
  font-family: "Sen Bold", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "BandeinsStrange" !important;
}

.title {
  font-family: "BandeinsStrangeExtended" !important;
}

