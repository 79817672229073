.chat-view-layout {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 0.5rem;
}

.right-panel {
  height: calc(100% - 60px);
}

.training-relative-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.training-fade-out-overlay {
  position: absolute;
  width: 100%;
  height: 70px;
  z-index: 1;
  bottom: 0;
  background-image: linear-gradient(transparent, white);
}
